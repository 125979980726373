<template>
  <div class="container">
    <LayoutViewWrapperNarrow>
      <div class="content">
        <BaseH1 :text="'Potwierdzenie email'" :short="true" />
        <TextDescription
          :text="'Wiadomość z linkiem do weryfikacji została wysłana. Proszę sprawdź swoją skrzynkę'"
        />
        <ButtonLink
          :label="'Wyślij link ponownie'"
          @click="tryDispatchVerificationLink"
        />
        <TextHint>
          Błędny adres e-mail? <br />
          <a @click.prevent="handleRegistrationRedirect">Zarejestruj się</a>
          ponownie
        </TextHint>
      </div>
    </LayoutViewWrapperNarrow>
    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import {
  ROUTE_REGISTRATION_START_NAME,
  ROUTE_REGISTRATION_WELCOME_NAME,
} from "@/router/constants";

import useEmailVerification from "@/composables/useEmailVerification";
import useIsMobile from "@/composables/useIsMobile";
import useAuth from "@/composables/useAuth";

import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import NavMobile from "@/components/NavMobile.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    TextDescription,
    ButtonLink,
    NavMobile,
    TextHint,
  },

  setup() {
    const userStore = useUserStore();
    const { isMobile } = useIsMobile();
    const { sendEmailVerificationLink } = useEmailVerification();
    const ROUTE_REGISTRATION = { name: ROUTE_REGISTRATION_START_NAME };

    const tryDispatchVerificationLink = async () => {
      try {
        if (userStore.emailIsVerified) {
          redirectToWelcome();
          return;
        }
        await sendEmailVerificationLink();
      } catch (error) {
        alert(error.message);
      }
    };

    const router = useRouter();
    const { handleLogout } = useAuth();

    const redirectToWelcome = () => {
      router.push({ name: ROUTE_REGISTRATION_WELCOME_NAME });
    };

    const handleRegistrationRedirect = async () => {
      await router.push(ROUTE_REGISTRATION);
      handleLogout();
    };

    onMounted(() => {
      if (userStore.emailIsVerificationLinkSent === false) {
        tryDispatchVerificationLink();
      }
    });

    return {
      tryDispatchVerificationLink,
      handleRegistrationRedirect,
      isMobile,
    };
  },
};
</script>

<style scoped>
.container {
  padding-bottom: var(--header-default--height);
}
.content {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 80px;
}

@media (max-width: 1200px) {
  .container {
    padding-bottom: 0px;
  }
  .content {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 80px;
  }
}
</style>
